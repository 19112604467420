.buyurtmalar-page {
  padding: 20px;
}

.order-list {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 10px;
  gap: 20px;
}

.order-card {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 500px;
  /* height: 500px; */
}

.order-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 20px;
}

.order-details {
  display: flex;
  flex-direction: column;
}

.order-details h3 {
  margin: 0;
}

.total-sum {
  margin-top: 20px;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: right;
}
