.h4 {
  background-color: #70bf56;
  margin-top: 40px;
  padding-top: 8px;
  width: 1450px;
  margin-left: 110px;
  border-radius: 2px;
  box-shadow: #00e600;
  margin-top: 680px;
  margin-bottom: 80px;
}

h1 {
  font-size: 22px;
  font-weight: bold;
}

.savatcha-container {
  max-width: 1140px;
  width: 90%;
  margin: 0 auto;
  padding: 20px 10px;
  font-family: Arial, sans-serif;
}
.savat-buttonlar {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}
.savat-buttonlar button {
  margin-left: 0;
}

/* h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
} */

.cart-list {
  list-style-type: none;
  padding: 0;
}

.cart-item {
  display: flex;
  align-items: center;
  padding: 15px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.cart-item img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-right: 15px;
  border-radius: 8px;
}

.cart-item-details {
  flex-grow: 1;
}

.cart-item-name {
  font-weight: bold;
  font-size: 1.1rem;
}

button {
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  padding: 5px 10px;
  transition: background-color 0.3s;
  margin-left: 5px;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

button:hover:not(:disabled) {
  background-color: #218838;
}

button:focus {
  outline: none;
}

button + button {
  margin-left: 10px;
}

@media (max-width: 768px) {
  .savatcha-container {
    padding: 15px;
  }

  button {
    font-size: 1rem;
    padding: 10px;
  }
  .savat-buttonlar button {
    width: 100%;
  }
}

.address {
  background-color: rgb(151, 206, 154);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  text-align: center;
  margin-top: 20px;
}

/* Label style */
.address label {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  display: block;
}

/* Input style */
.address input {
  width: 100%;
  padding: 12px;
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid #ccc;
  outline: none;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

/* Input focus style */
.address input:focus {
  border-color: #4caf50;
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.2);
}

/* Placeholder style */
.address input::placeholder {
  color: #888;
  font-style: italic;
}

/* Responsive for smaller screens */
@media (max-width: 500px) {
  .address {
    width: 90%;
  }
}
