.cart {
  max-width: 1440px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
}

.search-col {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.search {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.search .input {
  border-radius: 20px;
  border: 2px solid #22c322;
  margin-top: 20px;
  width: 300px;
  height: 40px;
  text-align: center;
}

.search .input::placeholder {
  font-size: 16px;
}

.search .button {
  border-radius: 20px;
  background-color: #00e600;
  border: 1px;
  width: 140px;
  height: 38px;
  margin-left: 20px;
  font-size: 20px;
  margin-top: 16px;
  color: white;
  padding: 0;
}

.h2 {
  margin-top: 20px;
  color: #269d26;
  font-size: 30px;
  padding: 0 10px;
  text-align: center;
}

.hr2 {
  background-color: #70bf56;
  margin-top: 40px;
  padding-top: 8px;
  max-width: 1440px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 2px;
  box-shadow: #00e600;
  margin-top: 80px;
  margin-bottom: 80px;
}

.box1 {
  border: 5px solid #22c322;
  border-radius: 10px;
  background-color: white;
  margin-left: 10px;
  width: 280px;
  height: 291px;
  top: 404px;
  left: 67px;
  gap: 0px;
  border-radius: 10px 10px 10px 10px;
  border: 6px 0px 0px 0px;
  opacity: 0px;
  box-shadow: 0px 7px 15px 7px #00000040;
}

.product1 h2 {
  font-size: 17px;
  /* margin-left: 10px; */
}

.carticon {
  width: 70px;
  margin-left: 210px;
  margin-top: -85px;
  border-radius: 20px;
  background-color: #00e600;
}

.button1 button {
  background-color: yellow;
  border-radius: 20px;
  width: 250px;
  height: 40px;
  font-size: 18px;
  border: 1px solid yellow;
  margin-left: 10px;
}

.kamaz {
  width: 100%; /* Mahsulot rasm kengligini to'liq qiling */
  border-radius: 6px;
  height: auto; /* Balandligini avtomatik qiling */
  margin-top: 60px;
}

.products {
  display: flex;
  flex-wrap: wrap; /* Qatorni to'ldirish imkonini beradi */
  justify-content: center; /* Mahsulotlarni yuqoriga joylashtirish */
  gap: 40px;
  margin: 0; /* Marginlarni 0 ga o'zgartirish */
  margin-bottom: 200px;
  margin-top: 100px;
}

.product1 {
  /* Har bir mahsulot 33.333% kenglikni egallaydi va 20px bo'shliq */
  box-sizing: border-box; /* Padding va borderlarni hisobga olish */
  margin-bottom: 20px; /* Pastga bo'shliq qo'shish */
  max-width: 300px; /* Mahsulotning maksimal kengligini belgilash */
  height: auto; /* Mahsulot balandligini avtomatik qiling */

  display: flex;
  flex-direction: column;
  justify-content: center;
}
.product1 img {
  border-radius: 5px;
}
.li3 {
  align-items: center;
  justify-content: center;
  display: flex;
}

.li1,
.li2 {
  text-decoration: none;
  color: black;
  background-color: white;
  border: 4px solid green;
  padding: 10px 100px;
  font-size: 20px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.li2 {
  margin-left: 150px;
}
.product-img {
  width: 280px;
  height: 300px;
}

@media (max-width: 1024px) {
  .box1 {
    margin-left: -100px;
  }

  /* .t {
    margin-left: -100px;
  } */
}

@media (max-width: 600px) {
  .h2 {
    font-size: 16px;
  }
  .li3 {
    margin-right: -300px;
  }

  .li1,
  .li2 {
    text-decoration: none;
    color: black;
    background-color: white;
    border: 4px solid green;
    padding: 10px 50px;
    font-size: 15px;
    gap: 20px;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
  }
  .li3 {
    margin-left: -300px;
  }
  .search .input {
    width: 100%;
  }
  .search .button {
    width: 100%;
    margin-left: 0;
  }
  .product1 {
    max-width: 500px;
    width: 100%;
    padding: 0 20px;
  }
  .product-img {
    object-fit: cover;
    max-width: 500px;
    width: 100%;
  }
}

@media (max-width: 400px) {
  .h2 {
    margin-left: 20px;
  }
  .box1 {
    margin-left: -200px;
  }
  .t {
    margin-left: -200px;
  }
  .li3 {
    display: grid;
    margin-left: -340px;
  }
  .li2 {
    margin-top: 10px;
    width: 140px;
    margin-left: 40px;
  }
  .li1 {
    width: 140px;
    margin-left: 40px;
  }
}
