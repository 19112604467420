.profile {
  max-width: 1380px;
  width: 100%;
  margin: 20px auto 0 auto;
  padding: 40px;
}

.logout-button {
  background-color: red;
  margin-top: 20px;
}
