.mahsulotlar {
  max-width: 1440px;
  margin: 0 auto;
}
.mahsulot-wrapper {
  max-width: 1440px;
  width: 90%;
  margin: 20px auto;
}

.div100 {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.absolute-text {
  font-size: 22px;
  margin-top: 200px;
}
.hr5 {
  max-width: 1440px;
  width: 90%;
  margin: 0 auto;
}
.li2-2 {
  display: flex;
  justify-content: center;
  margin-left: 0;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.button1 {
  background-color: #4caf50; /* Green background */
  border: none; /* No border */
  color: white; /* White text */
  padding: 10px 20px; /* Padding */
  text-align: center; /* Center the text */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Inline-block to fit the button size */
  font-size: 16px; /* Font size */
  margin: 10px 0; /* Margin around the button */
  cursor: pointer; /* Pointer cursor on hover */
  border-radius: 5px; /* Rounded corners */
  transition: background-color 0.3s ease; /* Smooth transition for hover */
}

.button1:hover {
  background-color: #45a049; /* Darker green on hover */
}

.button1:active {
  background-color: #3e8e41; /* Even darker green when clicked */
  box-shadow: 0 5px #666; /* Shadow effect */
  transform: translateY(4px); /* Button pressed effect */
}

.div10 h2 {
  list-style-type: none;
  padding: 10px 60px;
  border-radius: 90px;
  border: 2px solid rgb(21, 207, 21);
  box-shadow: 1px 1px 1px 1px rgb(207, 242, 154);
  font-size: 20px;
  word-spacing: 1px;
  letter-spacing: 1px;
  font-family: Arial, Helvetica, sans-serif;
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  text-align: left;
  color: #149108;
}

.div11 h2 {
  list-style-type: none;
  padding: 10px 60px;
  border-radius: 90px;
  border: 2px solid rgb(21, 207, 21);
  box-shadow: 1px 1px 1px 1px rgb(207, 242, 154);
  font-size: 20px;
  word-spacing: 1px;
  letter-spacing: 1px;
  font-family: Arial, Helvetica, sans-serif;
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  text-align: left;
  color: #149108;
}
.div12 h2 {
  list-style-type: none;
  padding: 10px 60px;
  border-radius: 90px;
  border: 2px solid rgb(21, 207, 21);
  box-shadow: 1px 1px 1px 1px rgb(207, 242, 154);
  font-size: 20px;
  word-spacing: 1px;
  letter-spacing: 1px;
  font-family: Arial, Helvetica, sans-serif;
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  text-align: left;
  color: #149108;
}

.div100 {
  display: flex;
  gap: 20px;
  /* margin-left: 180px; */
  cursor: pointer;
}

.div13 h2 {
  list-style-type: none;
  padding: 10px 60px;
  border-radius: 90px;
  border: 2px solid rgb(21, 207, 21);
  box-shadow: 1px 1px 1px 1px rgb(207, 242, 154);
  font-size: 20px;
  word-spacing: 1px;
  letter-spacing: 1px;
  font-family: Arial, Helvetica, sans-serif;
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  text-align: left;
  color: #149108;
}

.div14 h2 {
  list-style-type: none;
  padding: 10px 60px;
  border-radius: 90px;
  border: 2px solid rgb(21, 207, 21);
  box-shadow: 1px 1px 1px 1px rgb(207, 242, 154);
  font-size: 20px;
  word-spacing: 1px;
  letter-spacing: 1px;
  font-family: Arial, Helvetica, sans-serif;
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  text-align: left;
  color: #149108;
}
.div15 h2 {
  list-style-type: none;
  padding: 10px 60px;
  border-radius: 90px;
  border: 2px solid rgb(21, 207, 21);
  box-shadow: 1px 1px 1px 1px rgb(207, 242, 154);
  font-size: 20px;
  word-spacing: 1px;
  letter-spacing: 1px;
  font-family: Arial, Helvetica, sans-serif;
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  text-align: left;
  color: #149108;
}
.div16 h2 {
  list-style-type: none;
  padding: 10px 60px;
  border-radius: 90px;
  border: 2px solid rgb(21, 207, 21);
  box-shadow: 1px 1px 1px 1px rgb(207, 242, 154);
  font-size: 20px;
  word-spacing: 1px;
  letter-spacing: 1px;
  font-family: Arial, Helvetica, sans-serif;
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  text-align: left;
  color: #149108;
}

.div101 {
  display: flex;
  gap: 100px;
  margin-left: 260px;
  cursor: pointer;
  margin-top: 0px;
  margin-left: 250px;
}

.active h2 {
  background-color: rgba(21, 207, 21, 0.3); /* Light green background */
}

.hr5 {
  background-color: #70bf56;
  margin-top: 40px;
  padding-top: 8px;
  /* width: 1450px;
  margin-left: 110px; */
  border-radius: 2px;
  box-shadow: #00e600;
  margin-top: 580px;
  margin-bottom: 80px;
}

@media (max-width: 1024px) {
  .div100 {
    margin-left: 00px;
    /* margin: 10px 10px; */
    gap: 10px;
  }
  .div10 {
    width: 350px;
  }
  .div10 h2 {
    font-size: 15px;
    text-align: center;
  }

  .div11 {
    width: 350px;
  }
  .div11 h2 {
    font-size: 15px;
    text-align: center;
  }
  .div12 {
    width: 350px;
  }
  .div12 h2 {
    font-size: 15px;
    text-align: center;
  }
  .div13 {
    width: 350px;
  }
  .div13 h2 {
    font-size: 15px;
    text-align: center;
  }
  .div14 {
    width: 350px;
  }
  .div14 h2 {
    font-size: 15px;
    text-align: center;
  }
  .div15 {
    width: 350px;
  }
  .div15 h2 {
    font-size: 15px;
    text-align: center;
  }
  .div16 {
    width: 8000px;
  }
  .div16 h2 {
    font-size: 15px;
    text-align: center;
  }
  .div101 {
    gap: 20px;
    margin-left: 100px;
    max-width: 400px;
  }
  .hr5 {
    /* width: 800px; */
  }
}

@media (max-width: 600px) {
  .mahsulotlar {
    /* display: grid; */
  }

  .div10 {
    /* margin-left: 20px; */
    width: 100%;
    padding: 0 20px;
  }
  .div11 {
    margin-left: -260px;
    width: 250px;
    margin-top: 200px;
  }
  .div12 {
    margin-left: -260px;
    width: 250px;
    margin-top: 100px;
  }
  .div101 {
    display: grid;
    margin-left: 300px;
    margin-top: -305px;
    gap: 0px;
  }

  .div13 {
    width: 250px;
  }
  .div14 {
    width: 250px;
  }
  .div15 {
    width: 250px;
  }
  .div16 {
    width: 250px;
  }
  .hr5 {
    /* width: 450px;
    margin-left: 70px; */
  }
}
@media (max-width: 400px) {
  .div100 {
    /* margin-left: 50px;
    margin-top: -10px; */
  }
  .div101 {
    margin-left: 73px;
    margin-top: -10px;
  }
  .hr5 {
    /* width: 300px;
    margin-left: 41px; */
  }
}
