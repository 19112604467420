.big_container {
  width: 300px;
  height: 600px;
  border: 8px solid rgb(73, 73, 161);
  margin-left: 800px;
  margin-top: -470px;
  border-radius: 40px;
  background-color: rgb(182, 206, 236);
}
.small_container h2 {
  font-size: 19px;
  font-weight: 600;
  width: 230px;
  height: 35px;
  border: 2px solid black;
  background-color: aliceblue;
  text-align: center;
}
.small_container {
  margin-left: 33px;
  margin-top: 50px;
}
.small_container input {
  font-size: 19px;
  font-weight: 600;
  width: 230px;
  height: 35px;
  border: 2px solid black;
  background-color: rgb(182, 206, 236);
  text-align: center;
}

.button3 {
  width: 230px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid yellow;
  background-color: yellow;
  font-size: 21px;
  margin-top: 10px;
  margin-left: 38px;
}
.button4 {
  width: 230px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid rgb(98, 255, 0);
  background-color: rgb(85, 255, 0);
  font-size: 21px;
  margin-top: 10px;
  margin-left: 38px;
}

@media (max-width: 1024px) {
  .big_container {
    margin-left: 350px;
  }
}
@media (max-width: 600px) {
  .big_container {
    margin-left: 140px;
    margin-top: 100px;
  }
  .button3 {
    margin-left: -43px;
  }
  .button4 {
    margin-left: -43px;
  }
}

@media (max-width: 400px) {
  .big_container {
    margin-left: 35px;
    margin-top: 100px;
  }
  .button3 {
    margin-left: -43px;
  }
  .button4 {
    margin-left: -43px;
  }
}
